import React, { useState, useEffect } from "react"

const NavBar= ()=> {

    const [navbar, setNavbar] = useState(true)

     
   const changeBackground = () => {
   
    if (window.scrollY >= window.innerHeight) {
      setNavbar(false)
    } else {
      setNavbar(true)
    }
  }

  useEffect(() => {
    changeBackground()
    window.addEventListener("scroll", changeBackground)
  })

   return (

    <nav id="header" className={navbar? "fixed w-full z-30 top-0 text-white bg-transparent" : "fixed w-full z-30 top-0 text-white bg-primary" }>
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <a className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
          
            <svg className="h-8 fill-current inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
                <g class="fill-white">
                    <path d="M32.57,58.1H2.8C.48,58.1,0,57.66,0,55.59V48.66c0-2.18.41-2.54,2.91-2.55H62.13c2.48,0,2.9.4,2.9,2.59v6.78c0,2.21-.47,2.62-3,2.62Z" />
                    <path d="M41,23H62.24c2.29,0,2.79.47,2.8,2.54v6.5c0,1.62-.6,2.16-2.42,2.16H19.41c-1.92,0-2.45-.5-2.48-2.25V25.48c0-2,.5-2.45,2.72-2.45Z" />
                    <path d="M49,11.15H35.53c-2.1,0-2.61-.47-2.61-2.39V2.12c0-1.53.64-2.1,2.32-2.12H62.65C64.39,0,65,.53,65,2.06c0,2.3,0,4.61,0,6.91,0,1.66-.56,2.15-2.4,2.16Q55.83,11.16,49,11.15Z" />
                  </g>
            </svg>
           <span className="pl-3 text-4xl font-semibold">Standard</span><span className="text-4xl font-thin tracking-tighter">Solutions</span>
          </a>
        </div>
        <div className="block lg:hidden pr-4">
          <button id="nav-toggle" className="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            <svg className="fill-white h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20" id="nav-content">
          <ul className="list-reset lg:flex justify-end flex-1 items-center text-xl">
          {/*   <li className="mr-3">
              <a className="inline-block py-2 px-4 text-white font-bold no-underline" href="#">About us</a>
            </li>
            <li className="mr-3">
              <a className="inline-block text-white no-underline hover:text-underline py-2 px-4" href="#">Services</a>
            </li>
            <li className="mr-3">
              <a className="inline-block text-white no-underline hover:text-underline py-2 px-4" href="#">Clients</a>
            </li>
            <li className="mr-3">
              <a className="inline-block text-white no-underline hover:text-underline py-2 px-4" href="#">Projects</a>
            </li> */}

          </ul>
       {/*    <button
            id="navAction"
            className="mx-auto lg:mx-0 hover:underline bg-burgundy text-white font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            Work with us
          </button> */}
        </div>
      </div>
      <hr className="border-b border-primary opacity-25 my-0 py-0" />
    </nav>
  );
}

export default NavBar;
