import Header  from './components/header';
import Footer from './components/footer';
function App() {
  return (
    <div className="divide-primary divide-opacity-30">
     <Header/>
    </div>
  );
}

export default App;
