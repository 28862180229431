import Background from '../assets/landing.jpg'


export default function Hero() {
  return (
  <div className="w-full bg-cover bg-center h-screen bg-primary bg-blend-multiply  outline-offset-2 outline-darkbrown outline-10" style={{backgroundImage:`url(${Background})`}}>
    <div class="pt-40 text-white">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
     
        <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <p class="uppercase tracking-loose w-full font-serif"></p>
          <h1 class="my-4 text-7xl font-bold leading-tight font-serif">
            Launching Soon!
          </h1>
          <p class="leading-normal text-2xl mb-8 font-serif">
            Standard Solutions  - built with passion!
          </p>
        {/*   <button class="mx-auto lg:mx-0 hover:underline bg-primary text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            Subscribe
          </button> */}
        </div>
       
        <div class="w-full md:w-3/5 py-6 text-center">
        
        </div>
      </div>
    </div>
 </div>
  )
}